import axios from "axios";
import React, { useEffect, useState } from "react";
import ImageUploader from "react-images-upload";
import { Redirect } from "react-router-dom";
import Spinner from "../components/Spinner.js";

const Photo = (props) => {
  const [photoUrl, setPhotoUrl] = useState("");
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setPhotoUrl("");

    axios.post("save2", {
      photo: pictureDataURLs[0],
      photoName: pictureFiles[0].name,
    });
  };

  useEffect(() => {
    axios
      .get("resume-photo")
      .then((response) => {
        setPhotoUrl(response.data);
        setPageLoaded(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          setRedirectToLogin(true);
        }
      });
  }, []);

  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }

  if (!pageLoaded) {
    return <Spinner />;
  }

  const OldImage = ({ url }) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            margin: "5%",
            padding: "15px",
            background: "#edf2f6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            width: "215px",
            height: "215px",
            objectFit: "cover",
            boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)",
            border: "11px solid #d0dbe4",
          }}
        >
          <img
            src={url}
            alt="Logo"
            style={{ width: 200, height: 200, objectFit: "cover" }}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <ImageUploader
        {...props}
        withIcon={true}
        onChange={onDrop}
        withPreview={true}
        buttonText="Add Photo"
        imgExtension={[".jpg", ".jpeg", ".png"]}
        maxFileSize={5242880}
        singleImage={true}
      />

      {photoUrl !== "" ? <OldImage url={photoUrl} /> : ""}

      {console.log(photoUrl)}
    </div>
  );
};

export default Photo;
