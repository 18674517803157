import { Text, View, Image } from '@react-pdf/renderer'
import styles from '../../Styles'

const ProfileImage = ({ url }) => (
  <>
    {url !== '' && (
      <Image
        style={{
          width: '70px',
          height: '70px',
          borderRadius: '90',
        }}
        src={url}
      />
    )}
  </>
)

const ProfileContainer = ({ resumeData, display }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20',
        marginBottom: display ? '20px' : '-65px',
        height: '150',
        fontFamily: 'Helvetica-Bold',
      }}
    >
      <ProfileImage url={resumeData.resume_photo} />

      <View
        style={{
          justifyContent: 'center',
        }}
      >
        <Text style={styles.name_text}>Kevin Williamson</Text>
      </View>
      <Text style={styles.profession_text}>Software Developer</Text>
      <View
        style={{
          marginTop: '10px',
          width: '10%',
          height: '1px',
          backgroundColor: '#FFF',
          textAlign: 'center',
        }}
      />
    </View>
  )
}

export default ProfileContainer;
