// @ts-ignore
import React from "react";

import {
  Document,
  Page,
  PDFDownloadLink,
  PDFViewer
} from "@react-pdf/renderer";

import Left from "./sections/Left";
import Right from "./sections/Right";
import Styles from "./Styles";

const Template1 = ({resumeData}) => {


  return (
    <Document>
    <Page size="A4" style={Styles.page}>
      <Left resumeData={resumeData} />
      <Right resumeData={resumeData} />
    </Page>
  </Document>
  )
}

export default Template1;
