import React, { useEffect, useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import "./styles.css";
import { LoginStatusContext } from "../App";
import Spinner from "../components/Spinner.js";

const Template = () => {

  const { setUserLogged } = useContext(LoginStatusContext);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [templateId, setTemplateId] = useState(0);

  const [redirectToDownload, setRedirectToDownload] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const selectTemplate = (templateId) => async () => {
    await axios
      .post("update-template", { template_id: templateId })
      .then((response) => {
        if (response && response.status === 202) {
          setRedirectToDownload(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          setUserLogged(false);
          setRedirectToLogin(true);
        }
      });
  };

  useEffect(() => {
    axios
      .get("template")
      .then((response) => {
        setTemplateId(response.data.resume.resume_template);
        setPageLoaded(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          setUserLogged(false);
          setRedirectToLogin(true);
        }
      });

  }, [templateId]);

  if (redirectToDownload) {
    return <Redirect to="/download" />;
  }

  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }
  
  if (!pageLoaded) {
    return <Spinner />;
  }


  let activeTemplate;

  const templates = [
    {
      "id": 1,
      "name": "Template 1",
      "thumbnail": ""
    },
    {
      "id": 2,
      "name": "Template 2",
      "thumbnail": ""
    },    {
      "id": 3,
      "name": "Template 3",
      "thumbnail": ""
    },
  ];


  return (
    <div className="album py-5"
      style={{ 
        width: "80%",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "4%",
        fontSize: "16px",
        textAlign: "center",
        cursor: "pointer",
        //border: "solid red 1px"
        }}
    >
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 g-5">

          {templates.map(item => {

            {if(item["id"] == templateId) { activeTemplate = "selected-template"; } else { activeTemplate = ""; }}
            
            return(
              <div key={Math.random()} className="col" onClick={selectTemplate(item["id"])}>
                <div className={"card select-template shadow-sm " + activeTemplate } style={{ borderRadius: "0px" }}>
                  <img src="./assets/template_1.png" alt="logo" />
                </div>
              </div>
            );
            
          })}

        </div>
      </div>
    </div>
  );
};

export default Template;
