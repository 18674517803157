import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import "./styles.css";

const Logout = () => {
  
  const [redirectToHome, setRedirectToHome] = useState(false);

  useEffect(() => {
    localStorage.removeItem("user");

    axios.post("logout").then(setRedirectToHome(true));
  }, []);

  if (redirectToHome) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container">
      <h1>Logged out</h1>
    </div>
  );
};

export default Logout;
