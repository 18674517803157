import React, { useEffect, useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import "./styles.css";
import { LoginStatusContext } from "../App";
import Spinner from "../components/Spinner.js";


const User = () => {

  const { setUserLogged } = useContext(LoginStatusContext);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    axios
      .get("user")
      .then((response) => {
        setUserData(response.data);
        setPageLoaded(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          setUserLogged(false);
          setRedirectToLogin(true);
        }
      });
  }, []);

  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }

  if (!pageLoaded) {
    return <Spinner />;
  }

  return (
    <div style={{ 
      display: "flex",
      flexWrap: "wrap",
      width: "70%",
      maxWidth: "600px",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "4%",
      fontSize: "16px",
      }}>
      
      <pre style={{ textAlign: "left" }}>
        {JSON.stringify(userData, null, 2)}
      </pre>
    </div>
  );
};

export default User;
