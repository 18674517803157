import React from "react";
import { Text, View } from "@react-pdf/renderer";
import Divider from "./Divider";
import StyleT1 from "../../StyleT1";

const Education = ({education}) => {
  return (
    <View>
      <Text style={StyleT1.section_header_text_pdf} >
        Education
      </Text>

      <Divider />

      {education.map(({ school, degree }) => (
        <Text key={Math.random()} style={StyleT1.section_text_pdf}>
          School: {school} Degree: {degree}
        </Text>
      ))}
    </View>
  );
};

export default Education;
