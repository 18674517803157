import React from "react";

import {
  Document,
  Page,
  PDFDownloadLink,
  PDFViewer,
  View,
  Image,
  Text,
  Font
} from "@react-pdf/renderer";


Font.register({
  family: "Arial",
  fonts: [
    {
      src: 'fonts/Arial.ttf'
    },
  ],
});


const Download3 = ({resumeData}) => {
  return (
    <Document>
      <Page size="A4">
        <View>
          <Text>Summary: {resumeData.summary}</Text>

          <Text>Experience</Text>
          {resumeData.jobs.map(({ job_title, employer }) => (
              <Text style={{ fontSize: 11, fontFamily: "Arial" }} key={Math.random()}>
                Employer: {employer} Title: {job_title}
              </Text>
          ))}

          <Text>Education</Text>
          {resumeData.education.map(({ school, degree }) => (
              <Text style={{ fontFamily: "Arial", fontSize: 11 }} key={Math.random()}>
                School: {school} Degree: {degree}
              </Text>
          ))}
        </View>
      </Page>
  </Document>
  )
}

export default Download3;
