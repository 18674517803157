import React from "react";

const Preview3 = ({ resumeData }) => {

  return (
    <>
      <div style={{
            width: "60%", marginLeft: "auto", marginRight: "auto"
          }}>
        Resume name: {resumeData.resume.resume_name}<br/>        
        First name: {resumeData.resume.first_name}<br/>
        Last name: {resumeData.resume.last_name}<br/>
        Profession: {resumeData.resume.profession}<br/>
        Summary: {resumeData.summary}<br/>

        <hr/>
        Employment History<br/>
        {resumeData.jobs.map(({ job_title, employer }) => (
          <div key={Math.random()}>
            Employer: {employer} Title: {job_title}
          </div>
        ))}

        <hr/>
        Education<br/>
        {resumeData.education.map(({ school, degree }) => (
          <div key={Math.random()}>
            School: {school} Degree: {degree}
          </div>
        ))}
        <hr/>
      </div>



      <pre>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(resumeData, null, 2)}
                </pre>





    </>
  );
};

export default Preview3;
