import React, { useEffect, useState } from "react";
import { Divider, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FieldArray, Form, Formik, getIn } from "formik";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Spinner from "../components/Spinner.js";
import configs from "../config.js";


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "70%",
    minWidth: "560px",
    maxWidth: "900px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "4%",
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
    align: "center",
  },
  field: {
    margin: theme.spacing(1),
  }
}));

const Resume = () => {

  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const classes = useStyles();
  const [postedInitialValues, setPostedInitialValues] = useState({});

  useEffect(() => {
    axios
    .get("resume")
    .then((response) => {

      let responseData = response.data;

      responseData["jobs"].forEach(function (arrayItem) {
        arrayItem.id = Math.random();
      });

      responseData["education"].forEach(function (arrayItem) {
        arrayItem.id = Math.random();
      });

      setPostedInitialValues(responseData);
      setPageLoaded(true);

    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        setRedirectToLogin(true);
      }
    });

  }, []); // useEffect


  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }

  if (!pageLoaded) {
    return <Spinner />;
  }


  return (     
    <div className={classes.container}>

      {console.log(postedInitialValues)}

      <Formik
        initialValues={{
          contacts: [
            {
              id: Math.random(),
              email: postedInitialValues.contacts.email,
              phone: postedInitialValues.contacts.phone,
              firstName: postedInitialValues.resume.first_name,
              lastName: postedInitialValues.resume.last_name,
              profession: postedInitialValues.resume.profession,
              linkedIn: postedInitialValues.contacts.linkedin,
            },
          ],
          summary: [
            {
              id: Math.random(),
              summary: postedInitialValues.summary.summary,
            },
          ],
          jobs: postedInitialValues.jobs,
          education: postedInitialValues.education,
        }}

        onSubmit={(values) => {
          axios.post("update", {
            contacts: JSON.stringify(values.contacts),
            jobs: JSON.stringify(values.jobs),
            education: JSON.stringify(values.education),
            summary: JSON.stringify(values.summary),
          });
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, isValid }) => (
          <Form noValidate autoComplete="off">
            <FieldArray name="contacts">
              {() => (
                <div style = {{ paddingTop: "10px", paddingLeft: "50px", paddingRight: "50px" }}>
                  {values.contacts.map((p, index) => {

                    const firstName = `contacts[${index}].firstName`;
                    const touchedFirstName = getIn(touched, firstName);
                    const errorFirstName = getIn(errors, firstName);

                    const lastName = `contacts[${index}].lastName`;
                    const touchedLastName = getIn(touched, lastName);
                    const errorLastName = getIn(errors, lastName);

                    const profession = `contacts[${index}].profession`;
                    const touchedProfession = getIn(touched, profession);
                    const errorProfession = getIn(errors, profession);

                    const email = `contacts[${index}].email`;
                    const touchedEmail = getIn(touched, email);
                    const errorEmail = getIn(errors, email);

                    const phone = `contacts[${index}].phone`;
                    const touchedPhone = getIn(touched, phone);
                    const errorPhone = getIn(errors, phone);

                    const linkedIn = `contacts[${index}].linkedIn`;
                    const touchedLinkedIn = getIn(touched, linkedIn);
                    const errorLinkedIn = getIn(errors, linkedIn);

                    return (
                      <div key={p.id}>
                        <TextField style = {{ width: "45%" }}
                          className={classes.field} margin="normal" variant="outlined"
                          label="First Name" name={firstName} value={p.firstName}
                          helperText={touchedFirstName && errorFirstName ? errorFirstName : ""}
                          error={Boolean(touchedFirstName && errorFirstName)}
                          onChange={handleChange} onBlur={handleBlur}
                          autoFocus
                        />

                        <TextField style = {{ width: "45%" }}
                          className={classes.field} margin="normal" variant="outlined"
                          label="Last Name" name={lastName} value={p.lastName}
                          helperText={touchedLastName && errorLastName ? errorLastName : ""}
                          error={Boolean(touchedLastName && errorLastName)}
                          onChange={handleChange} onBlur={handleBlur}
                        />

                        <TextField style = {{ width: "45%" }}
                          className={classes.field} margin="normal" variant="outlined"
                          label="Email" name={email} value={p.email}
                          helperText={touchedEmail && errorEmail ? errorEmail : ""}
                          error={Boolean(touchedEmail && errorEmail)}
                          onChange={handleChange} onBlur={handleBlur}
                        />

                        <TextField style = {{ width: "45%" }}
                          className={classes.field} margin="normal" variant="outlined" 
                          label="Phone" name={phone} value={p.phone}
                          helperText={touchedPhone && errorPhone ? errorPhone : ""}
                          error={Boolean(touchedPhone && errorPhone)}
                          onChange={handleChange} onBlur={handleBlur}
                        />

                        <TextField style = {{ width: "45%" }}
                          className={classes.field} margin="normal" variant="outlined"
                          label="Profession" name={profession} value={p.profession}
                          helperText={touchedProfession && errorProfession ? errorProfession : ""}
                          error={Boolean(touchedProfession && errorProfession)}
                          onChange={handleChange} onBlur={handleBlur}
                        />


                        <TextField style = {{ width: "45%" }}
                          className={classes.field} margin="normal" variant="outlined"
                          label="LinkedIn" name={linkedIn} value={p.linkedIn}
                          helperText={touchedLinkedIn && errorLinkedIn ? errorLinkedIn : ""}
                          error={Boolean(touchedLinkedIn && errorLinkedIn)}
                          onChange={handleChange} onBlur={handleBlur}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </FieldArray>

            <Divider style={{ color: "black", marginTop: 20, marginBottom: 10 }} />

            <FieldArray name="summary">
              {() => (
                <div style = {{ paddingTop: "10px", paddingLeft: "50px", paddingRight: "50px" }}>
                  {values.summary.map((p, index) => {
                    const summary = `summary[${index}].summary`;
                    const touchedSummary = getIn(touched, summary);
                    const errorSummary = getIn(errors, summary);

                    return (
                      <div key={p.id}>
                        <TextField style = {{ width: "91.5%" }}
                          className={classes.field} margin="normal" variant="outlined"
                          label="Summary" name={summary} value={p.summary}
                          helperText={touchedSummary && errorSummary ? errorSummary : ""}
                          error={Boolean(touchedSummary && errorSummary)}
                          onChange={handleChange} onBlur={handleBlur}
                          multiline
                          minRows={2}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </FieldArray>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

            <FieldArray name="education">
              {({ push, remove }) => (
                <div style = {{ paddingTop: "20px", paddingLeft: "50px", paddingRight: "50px" }}>
                  {values.education.map(
                    (p, index) => {
                      const school = `education[${index}].school`;
                      const touchedFirstName = getIn(touched, school);
                      const errorFirstName = getIn(errors, school);

                      const degree = `education[${index}].degree`;
                      const touchedLastName = getIn(touched, degree);
                      const errorLastName = getIn(errors, degree);

                      const from_month = `education[${index}].from_month`;
                      const touchedFromMonth = getIn(touched, from_month);
                      const errorFromMonth = getIn(errors, from_month);

                      const from_year = `education[${index}].from_year`;
                      const touchedFromYear = getIn(touched, from_year);
                      const errorFromYear = getIn(errors, from_year);

                      const to_month = `education[${index}].to_month`;
                      const touchedToMonth = getIn(touched, to_month);
                      const errorToMonth = getIn(errors, to_month);

                      const to_year = `education[${index}].to_year`;
                      const touchedToYear = getIn(touched, to_year);
                      const errorToYear = getIn(errors, to_year);

                      return (
                        <div key={p.id} style = {{ textAlign: "center" }}>
                          <TextField style = {{ width: "45%" }}
                            className={classes.field} margin="normal" variant="outlined" 
                            label="School" name={school} value={p.school}
                            helperText={touchedFirstName && errorFirstName ? errorFirstName : ""}
                            error={Boolean(touchedFirstName && errorFirstName)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                         <TextField  style = {{ width: "45%", clear: "right" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Degree" name={degree} value={p.degree}
                            helperText={touchedLastName && errorLastName ? errorLastName : ""}
                            error={Boolean(touchedLastName && errorLastName)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <TextField style = {{ maxWidth: "140px" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Month (from)" name={from_month} value={p.from_month}
                            helperText={touchedFromMonth && errorFromMonth ? errorFromMonth : ""}
                            error={Boolean(touchedFromMonth && errorFromMonth)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <TextField style = {{ maxWidth: "140px" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Year (from)" name={from_year} value={p.from_year}
                            helperText={touchedFromYear && errorFromYear ? errorFromYear : ""}
                            error={Boolean(touchedFromYear && errorFromYear)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <TextField style = {{ maxWidth: "140px" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Month (to)" name={to_month} value={p.to_month}
                            helperText={touchedToMonth && errorToMonth ? errorToMonth : ""}
                            error={Boolean(touchedToMonth && errorToMonth)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <TextField style = {{ maxWidth: "140px" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Year (to)" name={to_year} value={p.to_year}
                            helperText={touchedToYear && errorToYear ? errorToYear : ""}
                            error={Boolean(touchedToYear && errorToYear)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                        <div style={{ clear: "left", paddingTop:"10px" }}>
                          <Button className={classes.button} type="button" color="secondary" variant="outlined" onClick={() => remove(index)}>x</Button>                              
                        </div>

                        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                        </div>
                      );
                    }
                  )}

                  <Button
                    className={classes.button} type="button" variant="outlined"
                    onClick={() => push({ id: Math.random(), school: "", degree: "", from_month: "", from_year: "", to_month: "", to_year: "" })}
                  >Add school</Button>
                </div>
              )}
            </FieldArray>

            <Divider style={{ marginTop: 20, marginBottom: 0 }} />

            <FieldArray name="jobs">
              {({ push, remove }) => (
                <div style = {{ paddingTop: "20px", paddingLeft: "50px", paddingRight: "50px" }}>
                  {values.jobs.map(
                    (p, index) => {
                      const employer = `jobs[${index}].employer`;
                      const touchedEmployer = getIn(touched, employer);
                      const errorEmployer = getIn(errors, employer);

                      const job_title = `jobs[${index}].job_title`;
                      const touchedLastName = getIn(touched, job_title);
                      const errorLastName = getIn(errors, job_title);

                      const description = `jobs[${index}].description`;
                      const touchedDescription = getIn(touched, description);
                      const errorDescription = getIn(errors, description);

                      const from_month = `jobs[${index}].from_month`;
                      const touchedFromMonth = getIn(touched, from_month);
                      const errorFromMonth = getIn(errors, from_month);

                      const from_year = `jobs[${index}].from_year`;
                      const touchedFromYear = getIn(touched, from_year);
                      const errorFromYear = getIn(errors, from_year);

                      const to_month = `jobs[${index}].to_month`;
                      const touchedToMonth = getIn(touched, to_month);
                      const errorToMonth = getIn(errors, to_month);

                      const to_year = `jobs[${index}].to_year`;
                      const touchedToYear = getIn(touched, to_year);
                      const errorToYear = getIn(errors, to_year);

                      return (
                        <div key={p.id} style = {{ textAlign: "center" }}>
                          <TextField style = {{ width: "45%" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Employer" name={employer} value={p.employer}
                            helperText={touchedEmployer && errorEmployer ? errorEmployer : ""}
                            error={Boolean(touchedEmployer && errorEmployer)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <TextField  style = {{ width: "45%", clear: "right" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Job Title" name={job_title} value={p.job_title}
                            helperText={touchedLastName && errorLastName ? errorLastName : ""}
                            error={Boolean(touchedLastName && errorLastName)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <TextField style = {{ width: "91.5%" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Description" name={description} value={p.description}
                            helperText={touchedDescription && errorDescription ? errorDescription : ""}
                            error={Boolean(touchedDescription && errorDescription)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <TextField style = {{ maxWidth: "140px" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Month (from)" name={from_month} value={p.from_month}
                            helperText={touchedFromMonth && errorFromMonth ? errorFromMonth : ""}
                            error={Boolean(touchedFromMonth && errorFromMonth)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <TextField style = {{ maxWidth: "140px" }} 
                            className={classes.field} margin="normal" variant="outlined"
                            label="Year (from)" name={from_year} value={p.from_year}
                            helperText={touchedFromYear && errorFromYear ? errorFromYear : ""}
                            error={Boolean(touchedFromYear && errorFromYear)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <TextField style = {{ maxWidth: "140px" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Month (to)" name={to_month} value={p.to_month}
                            helperText={touchedToMonth && errorToMonth ? errorToMonth : ""}
                            error={Boolean(touchedToMonth && errorToMonth)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <TextField style = {{ maxWidth: "140px" }}
                            className={classes.field} margin="normal" variant="outlined"
                            label="Year (to)" name={to_year} value={p.to_year}
                            helperText={touchedToYear && errorToYear ? errorToYear : ""}
                            error={Boolean(touchedToYear && errorToYear)}
                            onChange={handleChange} onBlur={handleBlur}
                          />

                          <div style={{ clear: "left", paddingTop:"10px" }}>
                            <Button type="button" color="secondary" variant="outlined" onClick={() => remove(index)}>x</Button>
                          </div>

                          <Divider style={{ marginTop: 25, marginBottom: 20 }} />
                        </div>
                      );
                    }
                  )}

                  <Button
                    className={classes.button} type="button" variant="outlined"
                    onClick={() => push({ id: Math.random(), employer: "", job_title: "", description: "", from_month: "", from_year: "", to_month: "", to_year: "" })}
                  >Add job</Button>
                </div>
              )}
            </FieldArray>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

            <Button className={classes.button} type="submit" color="primary" variant="contained" >save resume</Button>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

            {configs.devMode && (
              <>
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  ); // return

}; // const Resume

export default Resume;
