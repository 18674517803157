let configs = {};

configs.appUrl = "https://test-sh4otnwnmu.epicresume.ai";
configs.apiUrl = "https://test-sh4otnwnmu.epicresume.ai/api/";
configs.appName = "EpicResume.ai";

configs.devMode = true;
configs.useSpinner = true;

if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname === ""
) {
  configs.appUrl = "http://localhost:8000/";
  configs.apiUrl = "http://localhost:8000/api/";
}

export default configs;
