import React from "react";

import {
  Document,
  Page,
  PDFDownloadLink,
  PDFViewer,
  View,
  Image,
  Text,
  Font
} from "@react-pdf/renderer";


//DownloadStyles2["resume_container"].marginTop = "1000px";

Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: 'fonts/Ubuntu.ttf',
      fontWeight: 'normal',
    },
  ],
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: 'fonts/Roboto2.ttf',
      fontWeight: 'regular',
    },
  ],
});

const Styles = {
  page: {
    display: 'flex',
    flexDirection: 'row',
    color: "#000000",
    fontFamily: "Ubuntu",
    letterSpacing:  "0.5px",
  },
  container: {
    marginLeft: 30,
    marginTop: 30,
    marginBottom: 30,
    padding: 10,
    paddingTop: 20,
    width: "90%",
    border: "0px solid #000000",
  },
  summary_header: {
    fontSize: 11,
    fontWeight: 700,
  },
  summary_text: {
    fontSize: 11,
  },
  divider: {
    height: "0.5px",
    marginTop: "7px",
    marginBottom: "7px",
    width: "100%",
    backgroundColor: "#084c41",
    borderBottom: "none",
    borderLeft: "none",
    borderRight: "none",
    borderTop: "0.5px solid",
  },
  jobs_header: {
    marginTop: 20,
    fontSize: 11,
    fontWeight: 500,
  },
  jobs_row: {
    paddingBottom: "2px",
    fontSize: 11,
  },
  education_header: {
    marginTop: 20,
    fontSize: 11,
    fontWeight: 500,
  },
  education_row: {
    paddingBottom: "2px",
    fontSize: 11,
  },
};


const Divider = () => {
  return <View style={Styles.divider}></View>;
};

const About = ({summary}) => {
  return (
    <View>
      <Text style={Styles.summary_header}>Summary</Text>
      <Divider />
      <Text style={Styles.summary_text}>{summary}</Text>
    </View>
  );
};

const Jobs = ({jobs}) => {
  return (
    <View>
      <Text style={Styles.jobs_header}>Employment</Text>
      <Divider />
      {jobs.map(({ job_title, employer }) => (
        <Text style={Styles.jobs_row} key={Math.random()}>
          Employer: {employer} Title: {job_title}
        </Text>
      ))}
    </View>
  );
};

const Education = ({education}) => {
  return (
    <View>
      <Text style={Styles.education_header}>Education</Text>
      <Divider />
      {education.map(({ school, degree }) => (
        <Text style={Styles.education_row} key={Math.random()}>
          School: {school} Degree: {degree}
        </Text>
      ))}
    </View>
  );
};

const Download2 = ({resumeData}) => {
  return (
    <Document>
      <Page size="A4" style={Styles.page}>
      <View style={Styles.container}>
        <About summary={resumeData.summary} />
        <Jobs jobs={resumeData.jobs} />
        <Education education={resumeData.education} />
      </View>
      </Page>
  </Document>
  )
}

export default Download2;
