import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import configs from "./config.js";


axios.defaults.baseURL = configs.apiUrl;

axios.defaults.withCredentials = true;

ReactDOM.render(<App />, document.getElementById("root"));

