import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import "./styles.css";
import Template1 from "../templates/template1/Template1";
import { PDFViewer, PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import Spinner from "../components/Spinner.js";
import Styles from "../templates/template1/Styles";
import StyleT1 from "../templates/template1/StyleT1";
import Preview1 from "../templates/template1/Preview1";
import Preview2 from "../templates/template2/Preview2";

import Preview3 from "../templates/template3/Preview3";
import Download3 from "../templates/template3/Download3";

import Download2 from "../templates/template2/Download2";


const Download = () => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [resumeData, setResumeData] = useState({
    resume: { resume_template: 0 },
    jobs: [],
    education: [],
    phone: "",
    resume_photo: "",
    email: "",
  });

  useEffect(() => {

      axios
        .get("resume")
        .then((response) => {
          setResumeData({
            resume: response.data.resume,
            jobs: response.data.jobs,
            education: response.data.education,
            resume_photo: response.data.resume_photo,
            phone: response.data.contacts.phone,
            email: response.data.contacts.email,
            summary: response.data.summary.summary,
          });

          setPageLoaded(true);

          console.log(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("user");
            setRedirectToLogin(true);
          }
        });



  }, []);



//console.log(StyleT1);



  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }

  //console.log(resumeData.resume.resume_template);

  if (!pageLoaded) {
    return <Spinner />;
  }

  const downloadButton = (    
      <div 
        style={{ 
          textAlign: "center",
          paddingTop: "40px",
          paddingBottom: "20px"
        }}>
          <button type="submit" className="btn btn-primary login-button">Download Resume</button>
      </div>
  );

  return (
    <>
      {resumeData.resume.resume_template == 1 ? (
        <>
          <PDFDownloadLink document={<Template1 resumeData={resumeData} />} fileName="EpicResume.pdf">
            {() => downloadButton}
          </PDFDownloadLink>
          <Preview1 resumeData={resumeData} />
        </>
      ) : null}

      {resumeData.resume.resume_template == 2 ? (
        <>
          <PDFDownloadLink document={<Download2 resumeData={resumeData} />} fileName="EpicResume.pdf">
            {() => downloadButton}
          </PDFDownloadLink>
          <Preview2 resumeData={resumeData} />
        </>
      ) : null}

      {resumeData.resume.resume_template == 3 ? (
        <>
          <PDFDownloadLink document={<Download3 resumeData={resumeData} />} fileName="EpicResume.pdf">
            {() => downloadButton}
          </PDFDownloadLink>    
          <Preview3 resumeData={resumeData} />
        </>
      ) : null}

      {resumeData.resume.resume_template == 4 ? (
        <Preview2 resumeData={resumeData} />
      ) : null}
    </>
  );

}

export default Download;
