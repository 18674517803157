import React from "react";
import { View } from "@react-pdf/renderer";
import Styles from "../Styles";
import Jobs from "./right/Jobs";
import About from "./right/About";
import Education from "./right/Education";

const Right = ({resumeData}) => {
  
  return (
    <View style={Styles.section__right}>
      <About summary={resumeData.summary} />
      <Jobs jobs={resumeData.jobs} />
      <Education education={resumeData.education} />
    </View>
  );
};

export default Right;
