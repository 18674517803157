import React, { useContext }  from "react";
import configs from "../config.js";
import { LoginStatusContext } from "../App";
import { Redirect } from 'react-router';



const Home = () => {



    const { userLogged } = useContext(LoginStatusContext);

    if (userLogged) {
        return <Redirect to={ "/resume" } />;
      }



      

  return (

    <div>

    <section className="hero-4-bg position-relative" style={{ 
      background: "#4e21b7",
      paddingTop: 50,
      paddingBottom: 50
      }}>

        <div className="container" >
            <div className="align-items-center hero-4-content row"style={{ 

      maxWidth: 1100,
      paddingLeft: "7%"
      }}>




                <div className="col-sm-6" style={{ paddingLeft: 100, minWidth: 300 }}>



                    <h5 className="text-white hero-2-title font-weight-medium mb-4 line-height-1_4">1 - Select template</h5>
                    <h5 className="text-white hero-2-title font-weight-medium mb-4 line-height-1_4">2 - Add content</h5>
                    <h5 className="text-white hero-2-title font-weight-medium mb-4 line-height-1_4">3 - Download resume</h5>

                    <div className="mt-5">
                        <a className="epic-button epic-button-big" href="/template" style={{ textDecoration: "none", color: "#ffffff" }}>Start Your Resume</a>
                    </div>                 
                </div>

                <div className="col-sm-6">
                    <div className="hero-4-img mt-5 mt-lg-0">
                    <img src="hero.png" alt="Image" className="img-fluid mx-auto d-block" style={{ width: 350, paddingLeft: 10 }} />;
                    </div>
                </div>

            </div>
        </div>   
    </section>

    <section className="section position-relative" style={{ backgroundColor: '#fafafa;', fontSize: 15, paddingTop: 60, paddingBottom: 0, marginBottom: 0 }}>



















    <div className="container"  style={{ width: "70%", maxWidth: 1000, paddingBottom: 0, marginBottom: 0 }}>
        <div className="row" style={{ paddingBottom: 0, marginBottom: 0 }}>





        <h4>Your Career Deserves an Epic Start</h4>

<div  style={{ textAlign: 'justify', marginBottom: 10 }}>
 Epic Resume provides the tools and resources to craft a standout resume that highlights your unique talents and achievements. Whether you’re just beginning your career, transitioning to a new field, or aiming for the next big opportunity, Epic Resume is your trusted partner in building a professional resume that gets noticed.
</div>


<ul style={{ textAlign: 'justify' }}>
    <li style={{ marginLeft: 20, marginBottom: 10 }}><strong>Easy-to-Use Interface</strong>: Our intuitive platform guides you through each step of resume creation. Whether you're tech-savvy or a beginner, our easy-to-use interface ensures a smooth and enjoyable experience.</li>
    <li style={{ marginLeft: 20, marginBottom: 10 }}><strong>Visually Stunning Designs</strong>: Choose from a variety of templates tailored to different industries and career levels. Each template is crafted by experts to ensure your resume looks polished and professional.</li>

</ul>

<h4>Join the Epic Resume Community</h4>

<div  style={{ textAlign: 'justify', marginBottom: 30 }}>
Thousands of job seekers have transformed their careers with Epic Resume. Our commitment to excellence and user satisfaction has made us a trusted name in resume building. We are dedicated to helping you achieve your career goals with a resume that truly represents your epic potential.</div>


<h4>Get Started Now</h4>

<div  style={{ textAlign: 'justify', marginBottom: 0 }}>

Don’t let your resume hold you back. Start building your epic resume today and take the first step toward your dream job. With Epic Resume, professional success is just a few clicks away.</div>










        </div></div>

</section>

</div>


  );
};

export default Home;
