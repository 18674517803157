import React, { useContext } from "react";
import { LoginStatusContext } from "../App";


const Header = () => {

  const { userLogged } = useContext(LoginStatusContext);

  return ( 
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container px-5">
          <a className="navbar-brand" href={"/"}>
            <img src="./assets/logo.png" alt="logo" loading="lazy" style={{ width: "160px" }} />
          </a>

          <button className="navbar-toggler" 
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

              <li className="nav-item">
                <a className="nav-link" href={"/"}>Home</a>
              </li>

              {userLogged ? (
                <>
                  <li className="nav-item">
                    <a className="nav-link" href={"/pdfview"}>PdfView</a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href={"/user"}>User</a>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      type="link"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      href={"/"}
                    >Profile
                      <svg 
                        style={{ fontWeight: "bold", marginLeft: 7 }} xmlns="http://www.w3.org/2000/svg"
                        width="14" height="14" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path 
                          fill-rule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </a>

                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item" href="#">Change password</a></li>
                      <li><hr class="dropdown-divider" /></li>
                      <li><a class="dropdown-item" href="/logout">Logout</a></li>
                    </ul>
                  </li>
                </>

              ) : (

                <>
                  <li className="nav-item">
                    <a className="nav-link" href={"/register"}>Register</a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href={"/login"}>Login</a>
                  </li>
                </>

              ) }

            </ul>
          </div>
        </div>
      </nav>

      { userLogged ? (
        <div style={{ textAlign: "center", paddingTop: 5 }}>
          <a className="epic-button epic-button-small" href="/template" style={{ margin: 10, textDecoration: "none", color: "#ffffff" }}>Template</a>
          <a className="epic-button epic-button-small" href="/resume" style={{ margin: 10, textDecoration: "none", color: "#ffffff" }}>Edit</a>
          <a className="epic-button epic-button-small" href="/photo" style={{ margin: 10, textDecoration: "none", color: "#ffffff" }}>Photo</a>
          <a className="epic-button epic-button-small" href="/download" style={{ margin: 10, textDecoration: "none", color: "#ffffff" }}>Download</a>
        </div>
      ) : null }

    </>
  );
};

export default Header;
