import React from "react";

const Footer = () => {
  
  return (
    <footer className="py-4 mt-auto">
      <div className="container px-5">
        <div className="row align-items-center justify-content-between flex-column flex-sm-row">
          <div className="col-auto">
            <div className="small m-0 text-white">All Rights Reserved</div>
          </div>

          <div className="col-auto">
            <a className="link-light" href={"/terms"}>
              Terms
            </a>
            <a className="link-light privacy" href={"/privacy"}>
              Privacy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
