import React from "react";
import configs from "../config.js";

const HowToWriteAnEpicResume = () => {
  return (
    <main id="terms">
      <div className="container">
        <h5><strong>How to write an Epic resume</strong></h5>

        <p className="terms-paragraph">
          These terms (hereinafter referred to as "Terms" or "Terms of Use") govern your access and use of {configs.appName} website (hereinafter referred to as "Website", "We", "Us" or "Our"). By using the Website, you agree to comply with these Terms in their entirety. If you do not agree with any part of these Terms, please refrain from using the Website.
        </p>

        <p className="terms-header">1. Eligibility</p>
        <p clasNames="terms-paragraph">
          By using the Website, you represent and warrant that you are at least 16 years of age and have the legal capacity to enter into these Terms of Use. If you are under the age of 16, you must obtain consent from a parent or legal guardian before using the Website.
        </p>

        <p class="terms-header">2. User Conduct</p>
        <p class="terms-paragraph">
          You agree to use the Website for lawful purposes only and in a manner that does not violate any applicable laws, regulations, or the rights of others. Prohibited activities include but are not limited to:
          <br />
          a. Uploading or transmitting any content that is unlawful, harmful, threatening, abusive, defamatory, obscene, or otherwise objectionable.
          <br />
          b. Interfering with or disrupting the Website, its servers, or networks connected to the Website.
          <br />
          c. Attempting to gain unauthorized access to any portion of the Website or any other systems or networks connected to the Website.
          <br />
          d. Engaging in any activity that could damage, disable, overburden, or impair the proper functioning of the Website.
        </p>

        <p class="terms-header">3. Intellectual Property</p>
        <p class="terms-paragraph">
          The Website and its content, including but not limited to text, graphics, images, logos, trademarks, and software, are the property of {configs.appName} or its licensors and are protected by intellectual property laws. You may not reproduce, modify, distribute, or publicly display any of the content without the prior written consent of {configs.appName}.
        </p>

        <p class="terms-header">4. Privacy</p>
        <p class="terms-paragraph">
          Your privacy is important to us. Please review our Privacy Policy at <a href="/privacy">{configs.appUrl}privacy</a>, which explains how we collect, use, and disclose information from users of the Website.
        </p>

        <p class="terms-header">5. Disclaimer of Warranties</p>
        <p class="terms-paragraph">
          The Website is provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied. {configs.appName} does not guarantee the accuracy, completeness, or reliability of the content on the Website. You use the Website at your own risk.
        </p>

        <p class="terms-header">6. Limitation of Liability</p>
        <p class="terms-paragraph">
          In no event shall {configs.appName} or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use or inability to use the Website, even if {configs.appName} has been advised of the possibility of such damages.
        </p>

        <p class="terms-header">7. Indemnification</p>
        <p class="terms-paragraph">
          You agree to indemnify and hold harmless {configs.appName}, its officers, directors, employees, and agents from any and all claims, liabilities, damages, losses, or expenses (including attorneys' fees) arising from your breach of these Terms of Use or your use of the Website.
        </p>

        <p class="terms-header">8. Modifications to the Terms</p>
        <p class="terms-paragraph">
          {configs.appName} reserves the right to modify these Terms of Use at any time. Any changes will be effective immediately upon posting on the Website. Your continued use of the Website after the posting of any changes constitutes your acceptance of such changes.
        </p>

        <p class="terms-header">9. Governing Law and Jurisdiction</p>
        <p class="terms-paragraph">
          These Terms of Use shall be governed by and construed in accordance with the laws of Estonia, without regard to its conflict of laws principles. Any legal action or proceeding arising out of or relating to these Terms of Use shall be brought exclusively in the courts located in Tallinn, Estonia, and you consent to the personal jurisdiction of such courts.
        </p>

        <p class="terms-header">10. Severability</p>
        <p class="terms-paragraph">
          If any provision of these Terms of Use is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
        </p>

        <p class="terms-header">11. Entire Agreement</p>
        <p class="terms-paragraph">
          These Terms constitute the entire agreement between you and {configs.appName} concerning the subject matter herein and supersede all prior and contemporaneous negotiations and oral or written agreements between you and {configs.appName}.
        </p>
      </div>
    </main>
  );
};

export default HowToWriteAnEpicResume;
