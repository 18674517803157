import React from "react";
import { Font } from '@react-pdf/renderer';

//DownloadStyles2["resume_container"].marginTop = "1000px";

const DownloadStyles2 = {
  resume_container: {
    background: "white",
    margin: "0 auto",
    marginTop: "100px",
    marginBottom: "50px",
    boxShadow: "0 0 0.5cm rgba(0,0,0,0.5)",
    width: "21cm",
    height: "29.7cm",
    display: "flex",
    flexDirection: "row",
    paddingTop: "40px",
    paddingBottom: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },

  summary_header: {},

  jobs_header: {
    marginTop: "40px",
  },

  jobs_row: {
    paddingBottom: "2px",
  },

  education_hader: {
    marginTop: "40px",
  },

  education_row: {
    paddingBottom: "2px",
  },

  divider: {
    height: "0.5px",
    marginTop: "7px",
    marginBottom: "7px",
    width: "100%",
    backgroundColor: "#084c41",
    borderBottom: "none",
    borderLeft: "none",
    borderRight: "none",
    borderTop: "0.5px solid",
  },
};


const Preview2 = ({ resumeData }) => {




  return (
    <>
      <div style={DownloadStyles2.resume_container}>
        <div>

          <div style={DownloadStyles2.summary_header}>Summary156</div>
          <div style={DownloadStyles2.divider}></div>
          <div>{resumeData.summary}</div>


          <div style={DownloadStyles2.jobs_header}>
            Employment History
          </div>
          <div style={DownloadStyles2.divider}></div>

          {resumeData.jobs.map(({ job_title, employer }) => (
            <div style={DownloadStyles2.jobs_row} key={Math.random()}>
              Employer: {employer} Title: {job_title}
            </div>
          ))}


          <div style={DownloadStyles2.education_hader}>Education</div>
          <div style={DownloadStyles2.divider}></div>

          {resumeData.education.map(({ school, degree }) => (
            <div style={DownloadStyles2.education_row} key={Math.random()}>
              School: {school} Degree: {degree}
            </div>
          ))}

        </div>
      </div>

    </>
  );
};

export default Preview2;
