import React from "react";
import { Text, View } from "@react-pdf/renderer";
import Styles from "../../Styles";
import Divider from "./Divider";
import StyleT1 from "../../StyleT1";


const aboutExample =
  "Recent college graduate with experience in various areas of software engineering, including infrastructure and data analytics. A fast learner who thrives on generating innovative ideas, trouble-shooting and problem-solving, and working with object-oriented programming languages including Python and Java.";

const About = ({summary}) => {
  return (
    <View>
      <Text style={StyleT1.section_header_text_pdf}>
        Professional Summary
      </Text>
      <Divider />
      <Text style={StyleT1.section_text_pdf}>{summary}</Text>
    </View>
  );
};

export default About;
