import React from "react";
import configs from "../config.js";

const Privacy = () => {
  return (
    <main id="privacy">
      <div className="container">

        <h5><strong>Privacy Policy</strong></h5>
        <p class="terms-paragraph">
          This privacy policy (hereinafter referred to as "Privacy Policy" or
          "Policy") explains how {configs.appName} website (hereinafter referred
          to as "Website", "We", "Us" or "Our") collect, use, disclose, and
          safeguard your personal information when you use Our services or
          interact with the Website. Please read this Policy carefully to
          understand Our practices regarding your personal data and how We will
          treat it. By accessing or using the Website, you agree to the terms
          and conditions of this Privacy Policy.
        </p>

        <p class="terms-header">1. Types of Data Collected</p>
        <p class="terms-paragraph">
          We may collect various types of personal information about you when you use the Website.
          <br />
          This may include:
          <ul>
            <li>Name and contact information (e.g., email address, postal address, phone number).</li>
            <li>Account credentials (e.g., username, password).</li>
            <li>Demographic information (e.g., age, gender, location).</li>
            <li>
              Website usage data (e.g., IP address, browser type, operating 
              system, referring pages, pages visited, time spent on the
              Website).
            </li>
            <li>Information you provide through forms, surveys, or user-generated content (e.g., photos and text).</li>
            <li>Payment and transaction details</li>
            <li>Communication preferences and marketing opt-ins</li>
            <li>Any other information you voluntarily provide to Us</li>
          </ul>
        </p>

        <p class="terms-header">2. Purposes of Data Collection</p>
        <p class="terms-paragraph">
          We process your personal data for the following purposes:
          <ul>
            <li>To provide and maintain the Website's functionality</li>
            <li>To fulfill your requests for information or services</li>
            <li>To process transactions and payments (if applicable)</li>
            <li>To improve the Website's user experience and optimize performance</li>
            <li>To personalize your experience on the Website</li>
            <li>To communicate with you, respond to inquiries, and provide support</li>
            <li>To send you promotional materials and updates with your consent</li>
            <li>To analyze usage patterns and conduct research for statistical purposes</li>
            <li>To comply with legal obligations and protect our rights</li>
          </ul>
        </p>
        
        <p class="terms-header">3. Legal Basis for Data Processing</p>
        <p class="terms-paragraph">
          We will only process your personal data when there is a valid legal basis for doing so. The legal bases for
          processing may include:
          <ul>
            <li>Your consent: We may rely on your explicit consent for specific processing activities, such as marketing communications.</li>
            <li>Contractual necessity: We may process your data to fulfill contractual obligations or to take steps at your request before entering into a contract.</li>
            <li>Legitimate interests: We may process your data based on our legitimate interests as long as they do not outweigh your rights and freedoms.</li>
            <li>Legal compliance: We may process your data to comply with legal obligations to which We are subject.</li>
          </ul>
        </p>

        <p class="terms-header">4. Data Retention</p>
        <p class="terms-paragraph">
          We will retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, 
          unless a longer retention period is required or permitted by law.
        </p>
        
        <p class="terms-header">5. Data Sharing and Disclosure</p>
        <p class="terms-paragraph">
          We may share your personal data with third parties in the following
          circumstances:
          <ul>
            <li>With service providers and vendors who assist us in operating the Website and providing services to you.</li>
            <li>With business partners and affiliates for marketing purposes, if you have provided your consent.</li>
            <li>With legal and regulatory authorities when required to comply with the law or respond to legal requests.</li>
            <li>In connection with the sale, merger, or acquisition of all or a portion of Our business, provided that the receiving party agrees to treat your personal data in a manner consistent with this Privacy Policy.</li>
          </ul>
        </p>
        
        <p class="terms-header">6. Data Transfer</p>
        <p class="terms-paragraph">
          If you are accessing the Website from outside the European Economic Area (EEA),
          please note that your data may be transferred to, stored, and processed
          in countries where data protection laws may differ from those in your
          jurisdiction. By using the Website, you consent to the transfer of your
          data to countries outside your country of residence, including countries
          that may not provide the same level of data protection.
        </p>

        <p class="terms-header">7. Security</p>
        <p class="terms-paragraph">
        We implement reasonable security measures to protect your
        personal data from unauthorized access, disclosure, alteration, and
        destruction. However, please understand that no data transmission or
        storage system can be guaranteed to be 100% secure. While We strive to
        protect your information, We cannot warrant the security of any
        information you transmit to us or store on the Website.
        </p>
        
        <p class="terms-header">8. Changes to this Privacy Policy</p>
        <p class="terms-paragraph">
        We reserve the right to update or modify this Privacy
        Policy at any time. Any changes to the Privacy Policy will be posted on
        this page. Please review this Privacy Policy periodically to stay informed about how We collect,
        use, and protect your personal data.
        </p>

      </div>
    </main>
  );
};

export default Privacy;
