import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import "./styles.css";
import Template1 from "../templates/template1/Template1";
import { PDFViewer } from "@react-pdf/renderer";
import Spinner from "../components/Spinner.js";
import Styles from "../templates/template1/Styles";
// import Template2 from "../templates/template2/Template2";
import StyleT1 from "../templates/template1/StyleT1";
import Preview1 from "../templates/template1/Preview1";

import Download3 from "../templates/template2/Download3";


const PdfView2 = () => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [resumeData, setResumeData] = useState({
    template: 1,
    resume: {}
  });

  useEffect(() => {

      axios
        .get("resume")
        .then((response) => {
          setResumeData({
            template: response.data.resume.resume_template,
            resume: response.data
          });
          setPageLoaded(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("user");
            setRedirectToLogin(true);
          }
        });

  }, []);






  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }

  //console.log(resumeData.resume.resume_template);

  if (!pageLoaded) {
    return <Spinner />;
  }


console.log(555);
console.log(resumeData)

  return (

    
   

    <>
      <PDFViewer showToolbar={false} style={{ width: "100%", height: "1200px" }}>


  <Download3 resumeData={resumeData} />


</PDFViewer>
    </>
  );
}

export default PdfView2;
