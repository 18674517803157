import { Font } from '@react-pdf/renderer';
import configData from "../../config.json";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: configData.APP_URL + "fontsRoboto-Regular.ttf",
    },
    {
      src: configData.APP_URL + "fonts/Roboto-Bold.ttf",
      fontWeight: 'bold',
    },
    {
      src: configData.APP_URL + "fonts/Roboto-Medium.ttf",
      fontWeight: 'medium',
    },
    {
      src: configData.APP_URL + "fonts/Roboto-Light.ttf",
      fontWeight: 'light',
    },
    {
      src: configData.APP_URL + "fonts/Roboto-Black.ttf",
      fontWeight: 'black',
    },
  ],
});

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "fonts/Arial.ttf",
    },
  ],
});


Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});


const StyleT1 = {
  divider: {
    height: "0.5px",
    marginTop: "7px",
    marginBottom: "7px",
    width: "100%",
    backgroundColor: "#084c41",
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: '0.5px solid',
  },
  page: {
    background: 'white',
    margin: '0 auto',
    marginBottom: '100px',
    boxShadow: '0 0 0.5cm rgba(0,0,0,0.5)',
    width: '21cm',
    height: '29.7cm',
    display: 'flex',
    flexDirection: 'row',
  },
  left_section: {
    width: '25%',
    backgroundColor: '#084c41',
  },
  right_section: {
    margin: '10px',
    padding: '10px',
    paddingTop: '20px',
    width: '75%',
  },


  section_header_text_pdf: { 
    fontSize: "10.5pt",
    fontFamily: "Arial",
    letterSpacing: "0.5px",
    marginTop: '20px',
  },
  section_text_pdf: { 
    fontSize: "9.5pt",
    fontFamily: "Arial",
    letterSpacing: "0.5px",
    lineHeight: "1.4px",
  },


  job_conteiner: {
    paddingBottom: "1px",
    marginBottom: "1px",
    fontSize: "11px"
  }
};

export default StyleT1;
