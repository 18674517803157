import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import "./styles.css";
import Template1 from "../templates/template1/Template1";
import { PDFViewer } from "@react-pdf/renderer";
import Spinner from "../components/Spinner.js";
import Styles from "../templates/template1/Styles";
// import Template2 from "../templates/template2/Template2";
import StyleT1 from "../templates/template1/StyleT1";
import Preview1 from "../templates/template1/Preview1";

import Download2 from "../templates/template2/Download2";
import Download3 from "../templates/template3/Download3";

import Preview3 from "../templates/template3/Preview3";


const PdfView = () => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [resumeData, setResumeData] = useState({
    resume: { resume_template: 0 },
    jobs: [],
    education: [],
    phone: "",
    resume_photo: "",
    email: "",
    resumeData: {}
  });

  useEffect(() => {

      axios
        .get("resume")
        .then((response) => {
          setResumeData({
            resume: response.data.resume,
            jobs: response.data.jobs,
            education: response.data.education,
            resume_photo: response.data.resume_photo,
            phone: response.data.contacts.phone,
            email: response.data.contacts.email,
            summary: response.data.summary.summary,
            resumeData: response.data
          });

          setPageLoaded(true);

          console.log(111);
          console.log(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("user");
            setRedirectToLogin(true);
          }
        });



  }, []);




console.log(StyleT1);



  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }

  //console.log(resumeData.resume.resume_template);

  if (!pageLoaded) {
    return <Spinner />;
  }



  return (
    <>
      <PDFViewer showToolbar={false} style={{ width: "100%", height: "1200px" }}>

        {resumeData.resume.resume_template === 1 ? (
          <Template1 resumeData={resumeData} />
        ) : null }

        {resumeData.resume.resume_template === 2 ? (
          <Download2 resumeData={resumeData} />
        ) : null }

        {resumeData.resume.resume_template === 3 ? (
          <Download3 resumeData={resumeData} />
        ) : null }

      </PDFViewer>
    </>
  );
}

export default PdfView;
