import React, { useState, useContext } from "react";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import { AlertContainer, Alert } from "react-bs-notifier";
import { Redirect } from "react-router-dom";
import { LoginStatusContext } from "../App";

const Login = () => {

  const { userLogged, setUserLogged } = useContext(LoginStatusContext);
  const [showing, setShow] = useState("");
 
  function flashMessage(message) {
    setShow(message);


    setTimeout(() => { setShow(""); }, 4000);
  }

  if (userLogged) {
    return <Redirect to= { "/" } />;
  }

  return (
    <main id="login">




      <AlertContainer position="bottom-right">
        {showing ? (



          <Alert type="danger">
            <span className="flash-message">{showing}</span>
          </Alert>



        ) : null}
      </AlertContainer>

      <div className="form">
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={async (values) => {
            let email = values.email;
            let password = values.password;

            await axios
              .post("login", { email, password })
              .then((response) => {

console.log(response);


                if (response && response.status === 200 && response.data.status_text === "logged") {
                  localStorage.setItem("user", "logged");                  
                  setUserLogged(true);
                }
              })
              .catch((error) => {
                if (error.response && error.response.status === 401) {
                  flashMessage("Enter correct username and passwordzz");
                }
              });
          }}
        >

          <Form>
            <div className="login-header">
              Sign in
            </div> 

            <div className="card login-form floating-label">     
              <div className="input-field mb-4">
                  <Field id="username" type="text" name="email" autoComplete="username" autoFocus required />
                  <label>Enter email</label>
              </div>

              <div className="input-field mb-4">
                  <Field id="password" type="password" name="password" autoComplete="current-password" required />
                  <label>Enter password</label>
              </div>

              <button type="submit" className="btn btn-primary login-button">Login</button>  
            </div>
          </Form>
        </Formik>

      </div>
    </main>
  );

};
export default Login;
