import React from "react";
import configs from "../config.js";

let spinner = '';

if (configs.useSpinner) {
  spinner = (<div className="spinner-border text-primary" role="status"><span className="sr-only"></span></div>);
}

    
const Spinner = () => {
  return (
    <div className="text-center mt-5">
      {spinner}
    </div>
  );
};

export default Spinner;
