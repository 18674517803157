import React from "react";
import StyleT1 from "./StyleT1";
import "./Preview1.css";

const Preview1 = ({ resumeData }) => {
  const aboutExample =
    "Recent college graduate with experience in various areas of software engineering, including infrastructure and data analytics. A fast learner who thrives on generating innovative ideas, trouble-shooting and problem-solving, and working with object-oriented programming languages including Python and Java.";

    const OldImage = ({url}) => {

console.log(1111111111111111);
console.log(url);
console.log(1111111111111111);


      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              margin: "5%",
              padding: "15px",
              background: "#edf2f6",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              width: "215px",
              height: "215px",
              objectFit: "cover",
              boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)",
              border: "11px solid #d0dbe4",
            }}
          >


            <img
              src={url}
              alt="Logo"
              style={{ width: 200, height: 200, objectFit: "cover" }}
            />
          </div>
        </div>
      );
    };



  return (
    <>
      <div>header</div>

      <div style={StyleT1.page}>
        {/* L E F T  S E C T I O N */}
        <div style={StyleT1.left_section}>

        <OldImage url={resumeData.resume_photo} />


        </div>

        {/* R I G H T  S E C T I O N */}
        <div style={StyleT1.right_section}>
          <div className="section-header-text-preview">
            Professional Summary
          </div>

          <div style={StyleT1.divider}></div>

          <div className="section-text-preview">{aboutExample}</div>

          <div className="section-header-text-preview" >
            Employment History
          </div>
          <div style={StyleT1.divider}></div>

          {resumeData.jobs.map(({ job_title, employer }) => (
            <div className="section-text-preview" key={Math.random()}>
              Employer: {employer} Title: {job_title}
            </div>
          ))}


          <div className="section-header-text-preview">Education</div>
          <div style={StyleT1.divider}></div>

          {resumeData.education.map(({ school, degree }) => (
            <div className="section-text-preview" key={Math.random()}>
              School: {school} Degree: {degree}
            </div>
          ))}
        </div>
      </div>

      <div>footer</div>
    </>
  );
};

export default Preview1;
