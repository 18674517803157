import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Resume from "./pages/Resume";
import User from "./pages/User";
import Template from "./pages/Template";
import PdfView from "./pages/PdfView";
import PdfView2 from "./pages/PdfView2";
import Download from "./pages/Download";
import Photo from "./pages/Photo";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import HowToWriteAnEpicResume from "./pages/HowToWriteAnEpicResume";

export const LoginStatusContext = createContext();

const App = () => {
  const [userLogged, setUserLogged] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("user") === "logged") {
      setUserLogged(true);
    }
  }, []);

  return (
    <LoginStatusContext.Provider value={{ userLogged, setUserLogged }}>
      <div className="App">
        <BrowserRouter>
          <Header />

          <Route path="/" exact component={Home} />
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/resume" component={Resume} />
          <Route path="/user" component={User} />
          <Route path="/template" component={Template} />
          <Route path="/pdfview" component={PdfView} />
          <Route path="/pdfview2" component={PdfView2} />
          <Route path="/download" component={Download} />
          <Route path="/photo" component={Photo} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/how-to-write-an-epic-resume" component={HowToWriteAnEpicResume} />

          <Footer />
        </BrowserRouter>
      </div>
    </LoginStatusContext.Provider>
  );
};

export default App;
