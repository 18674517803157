import React from "react";
import { Text, View } from "@react-pdf/renderer";
import Divider from "./Divider";
import StyleT1 from "../../StyleT1";

// ts-ignore
const Jobs = ({jobs}) => {
  return (
    <View>
      <Text style={StyleT1.section_header_text_pdf}>
        Employment History
      </Text>

      <Divider />

      {jobs.map(({ job_title, employer }) => (
        <Text key={Math.random()} style={StyleT1.section_text_pdf}>
          Employer: {employer} Title: {job_title}
        </Text>
      ))}
    </View>
  );
};

export default Jobs;
