import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer'
import Styles from '../Styles';
import ProfileContainer from "./left/ProfileContainer";

const Left = ({resumeData}) => {

  return (
    <View style={Styles.section__left}>
      <ProfileContainer resumeData={resumeData} display={1} />
    </View>
  );
};

export default Left;
