import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { LoginStatusContext } from "../App";


const Register = () => {

  const [duplicate, setDuplicate] = useState(false);
  const { userLogged, setUserLogged } = useContext(LoginStatusContext);

  if (userLogged) {
    return <Redirect to={ "/" } />;
  }

  const SignupSchema = Yup.object().shape({
    email: Yup.string().required("Add Email").email("Enter valid Email"),
    password: Yup.string().min(4, "Use at least 4 characters"),
    passwordConfirm: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  return(
    <main id="register">
      <div className="form">
        <Formik
            initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
          }}
          validationSchema={SignupSchema}

          onSubmit={(data) => {
            axios
            .post("register", {
              first_name: data.firstName,
              last_name: data.lastName,
              email: data.email,
              password: data.password,
              password_confirm: data.passwordConfirm,
            })
            .then((response) => {
              if (response && response.status === 201) {

                let email = data.email;
                let password = data.password;
      
                axios.post("login", { email, password }).then((response) => {
                  if (response && response.status === 200) {
                    localStorage.setItem("user", "logged");
                    setUserLogged(true);
                  }
                });
              }
            })
            .catch((error) => {

              // setDuplicate(true);
              if (error.response && error.response.status === 409) { setDuplicate(true); }
            });
          }}
        >

          {({ errors, touched }) => (
            <Form>
              <div className="login-header">
                Register account
              </div> 

              <div className="card login-form floating-label">
                <div className="input-field mb-4">
                  <Field type="text" name="firstName" autocomplete="off" required />
                  <label>First name</label>
                </div>

                <div className="input-field mb-4">
                  <Field type="text" name="lastName" autocomplete="off" required />
                  <label>Last name</label>
                </div>

                <div className="input-field mb-4">
                  <Field name="email" type="text" autocomplete="off" required />
                  <label>Email</label>
                </div>
                {errors.email && touched.email ? <div className="validation-message">{errors.email}</div> : null}
                {duplicate && (<div className="validation-message">Email is used</div>)}

                <div className="input-field mb-4">
                  <Field name="password" type="password" autocomplete="off" required />
                  <label>Password</label>
                </div>
                {errors.password && touched.password ? (<div className="validation-message">{errors.password}</div>) : null}

                <div className="input-field mb-4">
                  <Field name="passwordConfirm" type="password" autocomplete="off" required />
                  <label>Re-enter Password</label>
                </div>
                {errors.passwordConfirm && touched.passwordConfirm ? (<div className="validation-message">{errors.passwordConfirm}</div>) : null}

                <button type="submit" className="btn btn-primary login-button">Register</button> 
              </div>

            </Form>
          )}
        </Formik>




<div  style={{ minHeight: 70 }}></div>


      </div>
    </main>
  );
};


export default Register;
